
import Vue from 'vue'

export default Vue.extend({
  name: 'PresseProjectList',

  data: () => {
    return {
      award: null as any,
      isLoading: true,
      limit: 10,
      orderBy: '',
      orderOptionsProjects: [
        {id: 0, value: 'projectDetails.ident', name: 'Ident'},
        {id: 1, value: 'titel', name: 'Titel'},
        {id: 2, value: 'awardtype', name: 'Kampagnenart'},
        {id: 3, value: 'fachbereich', name: 'Fachbereich'},
        {id: 4, value: 'ort', name: 'Ort'},
        {id: 5, value: 'typologie', name: 'Typologie'},
        {id: 6, value: 'userId', name: 'Benutzer ID'},
      ],
      page: 1,
      query: '',
      sort: '',
      sortOptions: [
        {id: 0, value: 'desc', name: 'absteigend'},
        {id: 1, value: 'asc', name: 'aufsteigend'},
      ],
    }
  },

  computed: {
    awards () {
      return this.$store.state.awards?.awards
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    count () {
      return this.$store.state.allProjectsCount
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    items () {
      return this.$store.state.allProjects
    },
    orderOptions () {
      return this.orderOptionsProjects
    },
    titles () {
      return this.$store.state.titles
    },
    type () {
      return this.$route.params.type
    },
    users () {
      return this.$store.state.users.users
    },
  },

  methods: {
    getProjectLinkText (p: any) {
      return p.titel + ', ' + p.bauherr_name + (p.bauherr_ort ? ' (' + p.bauherr_ort + ')' : '')
    },

    gotoPage (i: any) {
      this.page = i
      this.filterList()
    },

    filterList () {
      this.isLoading = true
      let res = ''
      if (this.query || this.orderBy || this.sort || this.award) res += '?'
      if (this.query) res += 'q=' + this.query
      if (this.query && this.orderBy) res += '&'
      if (this.orderBy) res += 'orderBy=' + this.orderBy
      if ((this.query || this.orderBy) && this.sort) res += '&'
      if (this.sort) res += 'orderMethod=' + this.sort
      if ((this.query || this.orderBy || this.sort) && this.award) res += '&'
      if (this.award) {
        let a = this.awards.filter((aw: any) => aw.id === this.award)[0]
        res += '/press/projekts/' + a.awardType + '/' + a.year + '/' + this.page
        if (this.$route.path !== '/press/projekts/' + a.awardType + '/' + a.year + '/' + this.page) {
          console.log('this.$route.path: ', this.$route.path);
          // this.$router.replace('/press/projekts/' + this.award)
        }
      }
      console.log('query (not in use right now): ', res)
      this.init()
    },

    init () {
      this.isLoading = true
      // old admin fetch
      // this.$store.dispatch('getProjects', query).then(() => {
      //   this.isLoading = false
      // })
      this.$store.dispatch('getProjectsPress', {
        awardType: this.currentAward.awardType,
        year: this.currentAward.year,
        page: this.page,
        query: this.query,
      }).then(() => {
        this.isLoading = false
      })
    },

    switchContext (val: any) {
      if (!val || (this.currentAward && val === this.currentAward.id)) {
        this.$store.commit('setCurrentAward', null)
        return this.filterList()
      }

      this.award = parseInt(val, 10)
      let res = this.awards?.filter((aw: any) => aw.id == this.award)[0]
      if (res) {
        this.$store.commit('setCurrentAward', res)
      }
      return this.filterList()
    },
  },

  created () {
    if (!this.awards || this.awards.length < 1) {
      this.$store.dispatch('getAwards')
    }
    if (this.$route.params.awardId) {
      this.switchContext(this.$route.params.awardId)
      this.award = parseInt(this.$route.params.awardId, 10)
    } else {
      this.filterList()
    }
  },
})
